import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-suspense',{key:_vm.key,attrs:{"promise":_vm.cargaProductos},model:{value:(_vm.dataProducto),callback:function ($$v) {_vm.dataProducto=$$v},expression:"dataProducto"}},[_c(VContainer,{staticClass:"mb-1",attrs:{"fluid":""}},[_c('h1',{staticClass:"verde_oscuro text-center"},[_vm._v(_vm._s(_vm.titulo))]),_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.dataProductos),function(producto){return _c(VCol,{staticClass:"col-lg-3 col-xl-3 col-xxl-3 col-md-3 col-sm-12 col-xs-12 col-12 cajaGaleria"},[_c(VCard,{staticClass:"cajaProductoCategoria text-center",attrs:{"elevation":"0"}},[_c('a',{staticClass:"enlaceCategoria",attrs:{"href":producto.ruta}},[_c(VImg,{attrs:{"lazy-src":require("@/assets/imagenes/lazy.svg"),"height":"300px","src":producto.imagenDestacada}})],1),_c(VCardTitle,{staticClass:"verde_oscuro nombreProductoCategoria text-center"},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(producto.nombre))])]),_c('div',{staticClass:"my-4 text-right mr-2"},[_c('h3',{staticClass:"rosa precioCategoria"},[_vm._v(_vm._s(producto.precio))])]),_c(VCardActions,[_c(VBtn,{staticClass:"rosa_fondo blanco",attrs:{"block":"","to":producto.ruta},on:{"click":function($event){return _vm.muestraEnlace(producto.ruta)}}},[_vm._v(" Ver más ")])],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }