<template>
    <vue-suspense v-model="dataProducto" :promise="cargaProductos" :key="key">
        <v-container fluid class="mb-1">
            <h1 class="verde_oscuro text-center">{{ titulo }}</h1>
            <v-row no-gutters>
                <v-col v-for="producto in dataProductos" class="col-lg-3 col-xl-3 col-xxl-3 col-md-3 col-sm-12 col-xs-12  col-12 cajaGaleria">
                    <v-card class="cajaProductoCategoria text-center" elevation="0">
                        <a :href="producto.ruta" class="enlaceCategoria">
                            <v-img
                                lazy-src="@/assets/imagenes/lazy.svg"
                                height="300px"
                                :src="producto.imagenDestacada"></v-img>
                        </a>
                        <v-card-title class="verde_oscuro nombreProductoCategoria text-center"><span class="text-center">{{ producto.nombre }}</span></v-card-title>
                        <div class="my-4 text-right mr-2"><h3 class="rosa precioCategoria">{{ producto.precio }}</h3></div>

                    

                        <v-card-actions>
                            <v-btn
                                block
                                class="rosa_fondo blanco"
                                :to="producto.ruta"
                                @click="muestraEnlace(producto.ruta)"
                            >
                                Ver más
                            </v-btn>
                        </v-card-actions>

                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </vue-suspense>
</template>
<script>
import axios from 'axios'

export default {
    name:'GaleriaProducto',

    props:{
        titulo:String,
        categoriaProducto:String
    },

    data() {
        return {
            dataProductos:''
        }
    }, 

    created() {
        
        this.cargaProductos()
    },

    methods : {
        cargaProductos:function() {
            
           this.categoriaProducto 
           axios
            .get(process.env.VUE_APP_RUTA_API+ "/listaProductos?slug=" + this.categoriaProducto + "&cantidad=12")
            .then(response => {
            this.dataProductos=response.data.producto
            console.log(this.dataProductos)
                })
           .catch(function (error) {
            if (error.response) {
             // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            })

        }, 

        muestraEnlace:function(enlace) {
            console.log(enlace)
        }
    }
}
</script>