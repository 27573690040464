import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VAppBar,{attrs:{"elevation":4,"color":"teal darken-3"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VFlex,{staticClass:"text-center"},_vm._l((_vm.dataCategorias),function(categoria){return _c(VBtn,{staticClass:"blanco",attrs:{"to":categoria.enlace,"plain":""}},[_c('b',[_vm._v(_vm._s(categoria.nombre))])])}),1)]},proxy:true}])},[_c(VAppBarNavIcon,{staticClass:"d-flex d-sm-none",attrs:{"color":"white"},on:{"click":function($event){_vm.drawer = true}}}),_c(VSpacer)],1),_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,_vm._l((_vm.dataCategorias),function(categoria){return _c(VListItem,[_c(VListItemTitle,[_c(VBtn,{attrs:{"plain":"","elevation":"0","to":categoria.enlace}},[_vm._v(_vm._s(categoria.nombre))])],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }