<template>
    <v-container fluid>
            <v-app-bar 
            :elevation="4" 
            color="teal darken-3">
                    <v-app-bar-nav-icon @click="drawer = true" color="white" class="d-flex d-sm-none"></v-app-bar-nav-icon> 
                    <!-- ? dm-sm-none permite ocultar el menu cuando -->

                <v-spacer></v-spacer>

                <template v-slot:extension>
                    <v-flex class="text-center">
                        <v-btn v-for="categoria in dataCategorias"
                            :to="categoria.enlace"
                            plain
                            class="blanco"
                        
                        >
                            <b>{{ categoria.nombre }}</b>
                        </v-btn>
                    </v-flex>
                </template>
            </v-app-bar>

             <!-- Navegación móviles -->
            <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            >
            <v-list
                nav
                dense
            >
                <v-list-item-group
                >
                <v-list-item v-for="categoria in dataCategorias">
                    <v-list-item-title>
                        <v-btn plain elevation="0" :to="categoria.enlace">{{ categoria.nombre }}</v-btn>
                    </v-list-item-title>
                </v-list-item>

                </v-list-item-group>
            </v-list>
            </v-navigation-drawer>
    <!-- Navigation bar ends -->

    </v-container>
</template>

<script>
import axios from 'axios';
import {def} from '@/definiciones.js';


export default {

    name:'Menu',

    data() {
        return {
            drawer:false,
            dataCategorias:''
        }
    }, 

    created() {
       this.obtenCategorias()
    },

    methods: {
        obtenCategorias:function() {
           axios
            .get(process.env.VUE_APP_RUTA_API  + "/categoriaProductos")
            .then(response => {
                console.log("Resultado Categorias")
                console.log(response.data.data)
            this.dataCategorias=response.data.data
                })
           .catch(function (error) {
            if (error.response) {
             // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
            })
        }
    }
}

</script>