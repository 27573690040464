<template>
    <v-container fluid class="mt-15">
            <div class="pie verde_intermedio_fondo blanco mt-5 text-center p-5">
                <v-row>
                     <!-- 
                    <v-col>
                        <menuVertical class="verde_intermedio_fondo" titulo="Sobre Casamoya" idMenu=50 ></menuVertical>
                    </v-col>
                    -->
                    <!--
                    <v-col>
                        <menuVertical class="verde_intermedio_fondo" titulo="Categorias" idMenu=49 ></menuVertical> 
                    </v-col>
                    -->
                    <v-col col-3 lg-3 md-3>
                        <v-card class="verde_intermedio_fondo blanco text-left pa-3">
                            <v-row>
                                <v-col>
                                    <h2 class="blanco text-center">¿Necesitas ayuda?</h2> 
                                    <br />
                                    <span class="text-left">Ubícanos en el whastapp : <strong class="font-weight-black"><a href="https://wa.link/2nvn33" target="_blank">+56936699469</a></strong></span>
                                    <br />
                                    <span class="text-left">Escríbenos al correo : <a class="blanco font-weight-black enlace" href="mailto:hola@casamoya.cl">hola@casamoya.cl</a></span>
                                </v-col>
                            </v-row>
                            <h2 class="blanco mt-3 text-center">Encuéntranos en redes sociales</h2>
                                <v-row class="text-center">
                                    <v-col>
                                        <a href="https://www.facebook.com/casamoyacl/" target="_blank">
                                            <v-img src="@/assets/imagenes/facebook.png" max-width="50px"></v-img>
                                        </a>
                                    </v-col>
                                    <v-col>
                                        <a href="https://www.instagram.com/casamoya.cl" target="_blank">
                                            <v-img src="@/assets/imagenes/instagram.png" max-width="50px"></v-img>
                                        </a>
                                    </v-col>
                                    <v-col>
                                        <a href="https://www.tiktok.com/@casamoya.cl" target="_blank">
                                            <v-img src="@/assets/imagenes/tiktok.png" max-width="50px"></v-img>
                                        </a>
                                    </v-col>
                                </v-row>
                        </v-card>
                        <br />
                       
                        <v-row>
                            
                        </v-row>
                    </v-col>
                </v-row>
            </div>
    </v-container>
</template>

<script>
import menuVertical from '@/components/pagina/menuVertical.vue'
    export default {
        name: 'Pie',

        components: {
            menuVertical
        }
    }
</script>